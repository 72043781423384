/* eslint-disable */
import { createRoutine } from 'redux-saga-routines'
import { call, fork, put, select, takeLatest } from '@redux-saga/core/effects'
import { dissoc, isEmpty, pathOr, propOr } from 'ramda'
import * as bpService from 'services/BpService'
import * as fileService from 'services/fileService'
import { toast } from 'react-toastify'
import * as unitService from 'services/unitsService'
import { isNotNilOrEmpty } from 'utils/ramda'
import { fileToUploadPath, thumbnailToUploadPath } from 'utils/files'
import { fetchUnitRoutine } from 'ducks/units/actions'
import { getApiErrors } from 'utils/errors'

export const getBpFilesRoutine = createRoutine('GET_BP_FILES')
export const getFileTypesRoutine = createRoutine('GET_FILE_TYPES')
export const uploadFileRoutine = createRoutine('UPLOAD_FILE')
export const updateFileRoutine = createRoutine('UPDATE_FILE')
export const shareFileRoutine = createRoutine('SHARE_FILE')
export const downloadFileRoutine = createRoutine('DOWNLOAD_FILE')
export const removeFileRoutine = createRoutine('REMOVE_FILE')
export const clearBpFilesRoutine = createRoutine('CLEAR_BP_FILES')
export const getSharedUnitsListForFileRoutine = createRoutine('GET_SHARED_UNITS_FOR_FILE')
export const clearSharedUnitsListForFileRoutine = createRoutine('CLEAR_SHARED_UNITS_FOR_FILE')
export const addFileVersionRoutine = createRoutine('ADD_FILE_VERSION')
export const getUploadProcessRoutine = createRoutine('GET_UPLOAD_PROCESS')
export const clearUploadProcessRoutine = createRoutine('CLEAR_UPLOAD_PROCESS')
export const saveUploadProcessFetchDetailsRoutine = createRoutine('SAVE_UPLOAD_PROCESS')
export const clearUploadProcessFetchDetailsRoutine = createRoutine('CLEAR_UPLOAD_PROCESS_DETAILS')

export const getFileTagsForBpRoutine = createRoutine('GET_FILE_TAGS_FOR_BP')
export const createFileTagRoutine = createRoutine('CREATE_FILE_TAG')
export const removeFileTagRoutine = createRoutine('REMOVE_FILE_TAG')
export const attachFileTagToFileRoutine = createRoutine('ATTACH_FILE_TAG_TO_BP')
export const syncFileTagsInFileRoutine = createRoutine('SYNC_FILE_TAGS')
export const syncFileTagsInFileVersionRoutine = createRoutine('SYNC_FILE_VERSION_TAGS')
export const detachFileTagFromFileRoutine = createRoutine('DETACH_FILE_TAG_FROM_BP')

function * uploadFile ({ payload }) {
  const {
    clearValues,
    uploadedImage,
    bpId,
    objectID,
    type,
    floorNumbers,
    producedBy,
    creationDate,
    isFloorPlan,
    fileTitle,
    legalOwner,
    expirationDate,
    expirationReminderDate,
    approvedBy,
    segment,
    dateOfIssue,
    isUnit,
    fileType,
    isPrivate
  } = payload
  yield put(uploadFileRoutine.request())
  try {
    const result = yield call(bpService.uploadFile, {
      uploadedImage,
      objectID,
      isFloorPlan,
      type
    })
    const path = propOr('', 'universalPath', result)

    if (isUnit) {
      yield call(unitService.addFileToUnit, {
        bpId,
        unitId: objectID,
        originalFileName: pathOr('', ['original_file_name', 0], result),
        name: isNotNilOrEmpty(fileTitle) ? fileTitle : pathOr('', ['original_file_name', 0], result),
        displayName: isNotNilOrEmpty(fileTitle) ? fileTitle : pathOr('', ['original_file_name', 0], result),
        path: fileToUploadPath(path, isFloorPlan),
        thumbnailPath: isFloorPlan ? thumbnailToUploadPath(path, true) : null,
        fileTypeId: fileType,
        isPrivate,
        legalOwner,
        floorNumbers,
        producedBy,
        creationDate,
        expirationDate,
        expirationReminderDate,
        approvedBy,
        segment,
        dateOfIssue
      })
      yield put(fetchUnitRoutine({ bpId: bpId, unitId: objectID }))
      yield put(uploadFileRoutine.success('unit'))
    } else {
      const updatedBp = yield call(bpService.updateBp, {
        bpId,
        id: objectID,
        files: [{
          originalFileName: pathOr('', ['original_file_name', 0], result),
          displayName: isNotNilOrEmpty(fileTitle) ? fileTitle : pathOr('', ['original_file_name', 0], result),
          path: fileToUploadPath(path, isFloorPlan),
          thumbnailPath: isFloorPlan ? thumbnailToUploadPath(path, true) : null,
          fileTypeId: fileType,
          legalOwner,
          floorNumbers,
          producedBy,
          creationDate,
          expirationDate,
          expirationReminderDate,
          approvedBy,
          segment,
          dateOfIssue
        }]
      })
      yield put(uploadFileRoutine.success(updatedBp.data.data))
    }
    typeof clearValues === 'function' && clearValues()
    toast.success('Adding a file..')
  } catch (error) {
    yield put(uploadFileRoutine.failure(error))
  }
}

function * uploadFileVersion ({ payload }) {
  const {
    bpId,
    unitId,
    uploadedImage,
    objectID,
    type,
    producedBy,
    creationDate,
    isFloorPlan,
    fileTitle,
    legalOwner,
    expirationDate,
    expirationReminderDate,
    approvedBy,
    segment,
    dateOfIssue,
    fileId,
    previousVersion,
    callback
  } = payload
  yield put(addFileVersionRoutine.request())
  try {
    const result = yield call(bpService.uploadFile, {
      uploadedImage,
      isFloorPlan,
      objectID,
      type
    })
    const path = propOr('', 'universalPath', result)
    if (type === 'building_unit_passport_file') {
      yield call(unitService.addUnitFileVersion, {
        fileId,
        bpId,
        unitId,
        previousVersion,
        path: fileToUploadPath(path, isFloorPlan),
        thumbnailPath: isFloorPlan ? thumbnailToUploadPath(path, true) : null,
        name: isNotNilOrEmpty(fileTitle) ? fileTitle : pathOr('', ['original_file_name'], result),
        producedBy,
        creationDate,
        legalOwner,
        expirationDate,
        expirationReminderDate,
        approvedBy,
        segment,
        dateOfIssue
      })
      yield put(fetchUnitRoutine({ bpId: bpId, unitId: objectID }))
    } else {
      yield call(bpService.addFileVersion, {
        id: fileId,
        previousVersion,
        path: fileToUploadPath(path, isFloorPlan),
        thumbnailPath: isFloorPlan ? thumbnailToUploadPath(path, true) : null,
        name: isNotNilOrEmpty(fileTitle) ? fileTitle : pathOr('', ['original_file_name'], result),
        producedBy,
        creationDate,
        legalOwner,
        expirationDate,
        expirationReminderDate,
        approvedBy,
        segment,
        dateOfIssue
      })
      yield put(getBpFilesRoutine({ id: bpId }))
    }
    yield put(addFileVersionRoutine.success())
    toast.success('Adding a file...')
    callback()
  } catch (error) {
    yield put(addFileVersionRoutine.failure(error))
    toast.error('Something went wrong')
  }
}

function * removeFile ({ payload }) {
  yield put(removeFileRoutine.request())
  const state = yield select()
  const bpId = pathOr('', ['bp', 'selectedBp', 'id'], state)
  try {
    yield call(bpService.removeFile, payload)
    yield put(removeFileRoutine.success(payload.id))
    yield put(getBpFilesRoutine({ id: bpId }))
    toast.success('File has been deleted')
  } catch (error) {
    yield put(removeFileRoutine.failure(error))
  }
}

function * getBpFiles ({ payload }) {
  const { callback, id } = payload
  yield put(getBpFilesRoutine.request())
  try {
    if (id) {
      const { data } = yield call(fileService.getFilesListForBp, dissoc('callback', payload))
      yield put(getBpFilesRoutine.success(data?.data || []))
      typeof callback === 'function' && callback()
    }
  } catch (error) {
    yield put(getBpFilesRoutine.failure(error))
  }
}

function * clearBpFiles () {
  yield put(clearBpFilesRoutine.success())
}

function * getFileTypes () {
  yield put(getFileTypesRoutine.request())
  try {
    const result = yield call(bpService.getFileTypes)
    yield put(getFileTypesRoutine.success(result.data.data))
  } catch (error) {
    yield put(getFileTypesRoutine.failure(error))
  }
}

function * updateFile ({ payload }) {
  const { clearValues } = payload
  yield put(updateFileRoutine.request())
  try {
    yield call(bpService.updateFile, payload)
    yield put(updateFileRoutine.success())
    toast.success('File has been updated')
    yield put(getBpFilesRoutine({ id: payload.bpId }))
    typeof clearValues === 'function' && clearValues()
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(updateFileRoutine.failure(error))
  }
}

function * shareFile ({ payload }) {
  yield put(shareFileRoutine.request())
  try {
    yield call(bpService.shareFileWithUnits, payload)
    yield put(shareFileRoutine.success())
    yield put(getBpFilesRoutine({ id: payload.bpId }))
    isEmpty(payload.buildingUnitPassportsIds)
      ? toast.success('File has been unshared')
      : toast.success('File has been shared')
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(shareFileRoutine.failure(error))
  }
}

function * getSharedUnitsListForFile ({ payload }) {
  yield put(getSharedUnitsListForFileRoutine.request())
  try {
    const { data } = yield call(bpService.getSharedUnitsListForFile, payload)
    yield put(getSharedUnitsListForFileRoutine.success(data.data))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(getSharedUnitsListForFileRoutine.failure(error))
  }
}

function * clearSharedUnitsListForFile () {
  yield put(clearSharedUnitsListForFileRoutine.success())
}

function * downloadFile ({ payload }) {
  yield put(downloadFileRoutine.request())
  try {
    const anchor = document.createElement('a')
    anchor.href = process.env.REACT_APP_API_URL + `/building-passport/files/${payload}/download`
    anchor.target = '_blank'
    anchor.download = 'file.pdf'
    anchor.click()
    yield put(downloadFileRoutine.success())
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(downloadFileRoutine.failure(error))
  }
}

function * saveUploadProcessId ({ payload }) {
  yield put(saveUploadProcessFetchDetailsRoutine.success(payload))
}

function * clearUploadProcessId () {
  yield put(clearUploadProcessFetchDetailsRoutine.success())
}

function * getUploadProcess ({ payload }) {
  yield put(getUploadProcessRoutine.request())
  try {
    if (isNotNilOrEmpty(payload.unitId)) {
      const { data } = yield call(fileService.getUnitMultiuploadProcessProgress, payload)
      yield put(getUploadProcessRoutine.success(data.data))
    } else {
      const { data } = yield call(fileService.getMultiuploadProcessProgress, payload)
      yield put(getUploadProcessRoutine.success(data.data))
    }
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(getUploadProcessRoutine.failure(error))
  }
}

function * getFileTagsForBp ({ payload }) {
  yield put(getFileTagsForBpRoutine.request())
  try {
    const { data } = yield call(fileService.getTagsListForBp, payload)
    yield put(getFileTagsForBpRoutine.success(data.data))

  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(getFileTagsForBpRoutine.failure(error))
  }
}

function * createFileTag ({ payload }) {
  const { callback } = payload
  yield put(createFileTagRoutine.request())
  try {
    const { data } = yield call(fileService.createFileTag, dissoc('callback', payload))
    yield put(createFileTagRoutine.success(data.data))

    typeof callback === 'function' && callback()
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(createFileTagRoutine.failure(error))
  }
}

function * removeFileTag ({ payload }) {
  yield put(removeFileTagRoutine.request())
  try {
    const { data } = yield call(fileService.removeFileTag, payload)
    yield put(removeFileTagRoutine.success(data.data))

    yield put(getFileTagsForBpRoutine({ bpId: payload.bpId }))
    yield put(getBpFilesRoutine({ id: payload.bpId }))
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(removeFileTagRoutine.failure(error))
  }
}

function * attachFileTagToFile ({ payload }) {
  const { callback } = payload
  yield put(attachFileTagToFileRoutine.request())
  try {
    const { data } = yield call(fileService.attachFileTagToFile, payload)
    yield put(attachFileTagToFileRoutine.success(data.data))

    typeof callback === 'function' && callback()
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(attachFileTagToFileRoutine.failure(error))
  }
}

function * syncFileTagsInFile ({ payload }) {
  const { callback, newTags } = payload
  yield put(syncFileTagsInFileRoutine.request())
  try {
    const { data } = yield call(fileService.syncFileTags, payload)
    yield put(syncFileTagsInFileRoutine.success(data.data))

    if (data.isTagDeleted || isNotNilOrEmpty(newTags)) {
      yield put(getFileTagsForBpRoutine({ bpId: payload.bpId }))
    }

    typeof callback === 'function' && callback()
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(syncFileTagsInFileRoutine.failure(error))
  }
}

function * syncFileTagsInFileVersion ({ payload }) {
  const { callback, newTags } = payload
  yield put(syncFileTagsInFileVersionRoutine.request())
  try {
    const { data } = yield call(fileService.syncFileVersionTags, payload)
    yield put(syncFileTagsInFileVersionRoutine.success(data.data))

    if (data.isTagDeleted || isNotNilOrEmpty(newTags)) {
      yield put(getFileTagsForBpRoutine({ bpId: payload.bpId }))
    }

    typeof callback === 'function' && callback()
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(syncFileTagsInFileVersionRoutine.failure(error))
  }
}

function * detachFileTagFromFile ({ payload }) {
  yield put(detachFileTagFromFileRoutine.request())
  try {
    const { data } = yield call(fileService.detachFileTagFromFile, payload)
    yield put(detachFileTagFromFileRoutine.success(data.data))

    if (data.isTagDeleted) {
      yield put(getFileTagsForBpRoutine({ bpId: payload.bpId }))
    }
  } catch (error) {
    toast.error(getApiErrors(error))
    yield put(detachFileTagFromFileRoutine.failure(error))
  }
}

function * clearUploadProcess () {
  yield put(clearUploadProcessRoutine.success())
}

export function * getFileTagsForBpWatcher () {
  yield takeLatest(getFileTagsForBpRoutine.TRIGGER, getFileTagsForBp)
}
export function * createFileTagWatcher () {
  yield takeLatest(createFileTagRoutine.TRIGGER, createFileTag)
}
export function * removeFileTagWatcher () {
  yield takeLatest(removeFileTagRoutine.TRIGGER, removeFileTag)
}
export function * attachFileTagToFileWatcher () {
  yield takeLatest(attachFileTagToFileRoutine.TRIGGER, attachFileTagToFile)
}
export function * syncFileTagsInFileWatcher () {
  yield takeLatest(syncFileTagsInFileRoutine.TRIGGER, syncFileTagsInFile)
}

export function * syncFileTagsInFileVersionWatcher () {
  yield takeLatest(syncFileTagsInFileVersionRoutine.TRIGGER, syncFileTagsInFileVersion)
}
export function * detachFileTagFromFileWatcher () {
  yield takeLatest(detachFileTagFromFileRoutine.TRIGGER, detachFileTagFromFile)
}

export function * getFileTypesWatcher () {
  yield takeLatest(getFileTypesRoutine.TRIGGER, getFileTypes)
}

export function * uploadFileWatcher () {
  yield takeLatest(uploadFileRoutine.TRIGGER, uploadFile)
}

export function * removeFileWatcher () {
  yield takeLatest(removeFileRoutine.TRIGGER, removeFile)
}

export function * getBpFilesWatcher () {
  yield takeLatest(getBpFilesRoutine.TRIGGER, getBpFiles)
}

export function * clearBpFilesWatcher () {
  yield takeLatest(clearBpFilesRoutine.TRIGGER, clearBpFiles)
}

export function * updateFileWatcher () {
  yield takeLatest(updateFileRoutine.TRIGGER, updateFile)
}

export function * shareFileWatcher () {
  yield takeLatest(shareFileRoutine.TRIGGER, shareFile)
}

export function * downloadFileWatcher () {
  yield takeLatest(downloadFileRoutine.TRIGGER, downloadFile)
}

export function * getSharedUnitsListForFileWatcher () {
  yield takeLatest(getSharedUnitsListForFileRoutine.TRIGGER, getSharedUnitsListForFile)
}

export function * clearSharedUnitsListForFileWatcher () {
  yield takeLatest(clearSharedUnitsListForFileRoutine.TRIGGER, clearSharedUnitsListForFile)
}

export function * uploadFileVersionWatcher () {
  yield takeLatest(addFileVersionRoutine.TRIGGER, uploadFileVersion)
}

export function * getUploadProcessWatcher () {
  yield takeLatest(getUploadProcessRoutine.TRIGGER, getUploadProcess)
}

export function * saveUploadProcessIdWatcher () {
  yield takeLatest(saveUploadProcessFetchDetailsRoutine.TRIGGER, saveUploadProcessId)
}

export function * clearUploadProcessIdWatcher () {
  yield takeLatest(clearUploadProcessFetchDetailsRoutine.TRIGGER, clearUploadProcessId)
}

export function * clearUploadProcessWatcher () {
  yield takeLatest(clearUploadProcessRoutine.TRIGGER, clearUploadProcess)
}

export const filesSagas = [
  fork(getFileTagsForBpWatcher),
  fork(createFileTagWatcher),
  fork(removeFileTagWatcher),
  fork(attachFileTagToFileWatcher),
  fork(syncFileTagsInFileWatcher),
  fork(syncFileTagsInFileVersionWatcher),
  fork(detachFileTagFromFileWatcher),
  fork(uploadFileWatcher),
  fork(updateFileWatcher),
  fork(removeFileWatcher),
  fork(getBpFilesWatcher),
  fork(clearBpFilesWatcher),
  fork(getFileTypesWatcher),
  fork(downloadFileWatcher),
  fork(getSharedUnitsListForFileWatcher),
  fork(clearSharedUnitsListForFileWatcher),
  fork(shareFileWatcher),
  fork(uploadFileVersionWatcher),
  fork(getUploadProcessWatcher),
  fork(saveUploadProcessIdWatcher),
  fork(clearUploadProcessIdWatcher),
  fork(clearUploadProcessWatcher)
]
